/* eslint-disable no-restricted-syntax */
import { getField, updateField } from 'vuex-map-fields';
import router from '@/router';
import {
  mixinDialogMutations,
  mixinDialogActions,
  mixinDialogGetters,
} from '../../mixins/vuex_dialogs';

export default {
  namespaced: true,
  state: {
    users: {
      all: [],
      filtered: [],
      selected: [],
      forDialog: {
        user: {},
        dialog: '',
      },
    },
    pagination: {
      currentPage: 1,
      perPage: 50,
    },
    dialogs: {
      add: false,
      referralCommission: false,
      changePlan: false,
      changePassword: false,
      changeTelegram: false,
      renameUser: false,
      addPayment: false,
      showTransactions: false,
      payReferal: false,
      openDeleteUser: false,
      changeReferralPercent: false,
      addRefUser: false,
      showDolphinPay: false,
      abuse: false,
      showRefPaymentsHistory: false,
      showRefRatesHistory: false,
      disableTwoFactor: false,
      suspiciousEntrance: false,
      twoFactorByEmailEnableOrDisable: false,
      history: false,
      resetLimit: false,
      userInfo: false,
    },
    suspiciousEntrance: {
      suspiciousValues: [],
      pagination: {
        currentPage: 1,
        perPage: 10,
      },
    },
    loading: {
      mainTable: false,
      suspiciousEntrance: false,
    },
    topbarSearch: {
      searchInput: '',
      refbringersSearchInput: '',
    },
    userTransactions: [],
    userRefTransactions: [],
    userRefRates: [],
    filters: {
      segments: [],
      grade: '',
      sortBy: '',
      order: '',
    },
    segments: [],
  },

  getters: {
    ...mixinDialogGetters,
    getField,

    users: (state) => state.users,
    loading: (state) => state.loading,
    dialogs: (state) => state.dialogs,
    topbarSearch: (state) => state.topbarSearch,
    pagination: (state) => state.pagination,
    userTransactions: (state) => state.userTransactions,
    userRefTransactions: (state) => state.userRefTransactions,
    userRefRates: (state) => state.userRefRates,
    suspiciousEntrance: (state) => state.suspiciousEntrance,
    forDialog: (state) => state.users.forDialog,
    filters: (state) => state.filters,
    segments: (state) => state.segments,
  },

  mutations: {
    ...mixinDialogMutations,
    updateField,

    SET_ALL_USERS: (state, payload) => {
      state.users.all = payload;
    },
    SET_ALL_REF_TRANSACTIONS: (state, payload) => {
      state.userRefTransactions = payload;
    },
    SET_ALL_REF_RATES: (state, payload) => {
      state.userRefRates = payload;
    },

    SET_SELECTED: (state, payload) => {
      state.users.selected = payload;
    },

    SET_PAGINATION: (state, payload) => {
      state.pagination = payload;
    },

    SET_SUSPICIOUS_ENTRANCE_PAGINATION: (state, payload) => {
      state.suspiciousEntrance.pagination = payload;
    },

    SET_USER_SUSPICIOUS_ENTRANCE_VALUES: (state, payload) => {
      state.suspiciousEntrance.suspiciousValues = payload;
    },

    RESET_USER_SUSPICIOUS_ENTRANCE: (state) => {
      state.suspiciousEntrance = {
        suspiciousValues: [],
        pagination: {
          currentPage: 1,
          perPage: 10,
        },
      };
    },

    SET_LOADING: (state, payload) => {
      state.loading[payload.key] = payload.value;
    },

    CLEAR_FOR_DIALOG: (state) => {
      state.users.forDialog.user = {};
      state.users.forDialog.dialog = '';
    },

    SET_FOR_DIALOG: (state, payload) => {
      state.users.forDialog.user = payload.user;
      state.users.forDialog.dialog = payload.dialog;
    },

    SET_SEGMENTS: (state, payload) => {
      state.segments = payload;
    },

    SET_FILTERS: (state, payload) => {
      state.filters = payload;
    },
  },

  actions: {
    ...mixinDialogActions,

    async loadUsers(context, payload = null) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/users?';

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.query) {
          request += `query=${encodeURIComponent(payload.query)}&`;
        }
        if (payload && payload.segments) {
          payload.segments.forEach((segment) => {
            request += `segments[]=${segment}&`;
          });
        }
        if (payload && payload.grade) {
          request += `grade=${payload.grade}&`;
        }
        if (payload && payload.sortBy) {
          request += `sortBy=${payload.sortBy}&`;
        }
        if (payload && payload.order) {
          request += `order=${payload.order}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: payload && payload.query ? payload.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_USERS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async getUserInfo(context, payload = null) {
      try {
        let request = `/admin/portraits/${payload.id}?`;

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.type) {
          request += `type=${payload.type}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        return response;
      } catch (err) {
        console.error(err);

        return false;
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadSuspiciousEntrance(context, payload = null) {
      await context.commit('SET_LOADING', { key: 'suspiciousEntrance', value: true });

      try {
        let request = `/admin/users/auth-history/${payload.id}?`;

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }

        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }

        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += 'page=1&limit=10';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
          };

          context.dispatch('setSuspiciousEntrancePagination', data);
          context.commit('SET_USER_SUSPICIOUS_ENTRANCE_VALUES', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'suspiciousEntrance', value: false });
      }
    },

    async changeTwoFactorDisableOrEnable(context, data = null) {
      const request = `/admin/users/update-two-factor-by-email/${data.id}`;

      await this._vm.api.post(request, {
        enable: data.twoFactorDisableOrEnable,
      });
    },

    async loadRefbringers(context, payload = null) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        const { query } = router.currentRoute;

        let request = '/admin/refbringers?';

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.query) {
          request += `query=${encodeURIComponent(payload.query)}&`;
        }
        if (payload && payload.sortBy) {
          request += `sortBy=${payload.sortBy}&`;
        }
        if (payload && payload.sortDesc) {
          request += `sortDesc=${+payload.sortDesc}&`;
        }
        if (query && query.filterDates) {
          request += `filterDates=${query.filterDates}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += `${!request.endsWith('?') ? '&' : ''}page=1&limit=50`;
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: payload && payload.query ? payload.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_USERS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadRefUserRefTransactionsHistory(context, payload) {
      try {
        const { query } = router.currentRoute;

        let request = '/admin/refbringers/transactions?';

        request += `userId=${payload.userId}&`;

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.query) {
          request += `query=${encodeURIComponent(payload.query)}&`;
        }
        if (payload && payload.sortBy) {
          request += `sortBy=${payload.sortBy}&`;
        }
        if (payload && payload.sortDesc) {
          request += `sortDesc=${+payload.sortDesc}&`;
        }
        if (query && query.filterDates) {
          request += `filterDates=${query.filterDates}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (payload === null || request.endsWith('?')) {
          request += `${!request.endsWith('?') ? '&' : ''}page=1&limit=50`;
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: payload && payload.query ? payload.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_REF_TRANSACTIONS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadRefUserRefRatesHistory(context, payload) {
      try {
        const { query } = router.currentRoute;

        let request = '/admin/refbringers/rates?';

        request += `userId=${payload.userId}&`;

        if (payload && payload.page) {
          request += `page=${payload.page}&`;
        }
        if (payload && payload.itemsPerPage) {
          request += `limit=${payload.itemsPerPage}&`;
        }
        if (payload && payload.sortBy) {
          request += `sortBy=${payload.sortBy}&`;
        }
        if (payload && payload.sortDesc) {
          request += `sortDesc=${+payload.sortDesc}&`;
        }
        if (query && query.filterDates) {
          request += `filterDates=${query.filterDates}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }
        if (request.endsWith('?')) {
          request += `${!request.endsWith('?') ? '&' : ''}page=1&limit=50`;
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: payload && payload.query ? payload.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_REF_RATES', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async addRefUser(context, payload) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        const request = '/admin/refbringers/add';

        const response = await this._vm.api.post(request, {
          refUserId: payload.refUserId,
          userEmail: payload.userEmail,
        });

        if (response.status === 200) {
          await context.dispatch('loadRefbringers');
        } else {
          alert('Ошибка');
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async addRefUserList(context, payload) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        const request = '/admin/refbringers/file';
        debugger;
        const response = await this._vm.api.post(request, payload.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          const message = `Удачно привязались: ${response.data.data.successed.join(', \r\n')
          }\r\nНе найдено: ${response.data.data.failed.join(', \r\n')
          }\r\nБыли ошибки: ${response.data.data.errors.join(', \r\n')}`;
          console.log(message);
          await context.dispatch('loadRefbringers');
          alert(message);
        } else {
          alert('Ошибка');
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async loadCurrentPage(context) {
      await context.commit('SET_LOADING', { key: 'mainTable', value: true });

      try {
        let request = '/admin/users?';

        if (context.state.pagination.currentPage) {
          request += `page=${context.state.pagination.currentPage}&`;
        }
        if (context.state.pagination.perPage) {
          request += `limit=${context.state.pagination.perPage}&`;
        }
        if (context.state.pagination.query) {
          request += `query=${encodeURIComponent(context.state.pagination.query)}&`;
        }
        if (context.state.filters.segments) {
          context.state.filters.segments.forEach((segment) => {
            request += `segments[]=${segment}&`;
          });
        }
        if (context.state.filters.grade) {
          request += `grade=${context.state.filters.grade}&`;
        }
        if (request.endsWith('&')) {
          request = request.slice(0, request.length - 1);
        }

        if (request.endsWith('?')) {
          request += 'page=1&limit=50';
        }

        const response = await this._vm.api.get(request);

        if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
          const data = {
            response: response.data,
            query: context.state.pagination.query ? context.state.pagination.query : null,
          };

          context.dispatch('setPagination', data);
          context.commit('SET_ALL_USERS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        await context.commit('SET_LOADING', { key: 'mainTable', value: false });
      }
    },

    async setPagination(context, payload) {
      const { response } = payload;
      const { query } = payload;

      const pagination = {
        currentPage: parseInt(response.current_page, 10),
        lastPage: parseInt(response.last_page, 10),
        firstPageUrl: response.first_page_url,
        nextPageUrl: response.next_page_url,
        prevPageUrl: response.prev_page_url,
        lastPageUrl: response.last_page_url,
        links: response.links,
        perPage: parseInt(response.per_page, 10),
        total: parseInt(response.total, 10),
        from: parseInt(response.from, 10),
        to: parseInt(response.to, 10),
        query,
      };

      await context.commit('SET_PAGINATION', pagination);
    },

    async setSuspiciousEntrancePagination(context, payload) {
      const { response } = payload;

      const pagination = {
        currentPage: parseInt(response.current_page, 10),
        lastPage: parseInt(response.last_page, 10),
        firstPageUrl: response.first_page_url,
        nextPageUrl: response.next_page_url,
        prevPageUrl: response.prev_page_url,
        lastPageUrl: response.last_page_url,
        links: response.links,
        perPage: parseInt(response.per_page, 10),
        total: parseInt(response.total, 10),
        from: parseInt(response.from, 10),
        to: parseInt(response.to, 10),
      };

      await context.commit('SET_SUSPICIOUS_ENTRANCE_PAGINATION', pagination);
    },

    async clearForDialog(context) {
      await context.commit('CLEAR_FOR_DIALOG');
    },

    async setForDialog(context, data) {
      await context.commit('SET_FOR_DIALOG', data);
    },

    async clearSelected(context) {
      context.commit('SET_SELECTED', []);
    },

    async resetUserSuspiciousEntrance(context) {
      context.commit('RESET_USER_SUSPICIOUS_ENTRANCE');
    },

    async setFilters(context, data) {
      context.commit('SET_FILTERS', data);
    },

    async loadSegmentsList(context) {
      try {
        const response = await this._vm.api.get('/admin/segments');

        if (response.status === 200) {
          context.commit('SET_SEGMENTS', response.data.data);
        }
      } catch (err) {
        console.error(err);
      }
    },

    async changeSelectedSegment(context, payload) {
      try {
        await this._vm.api.put(`/admin/segments/${payload.id}?segment=${payload.segment}`);

        context.dispatch('main/alert', {
          color: 'success',
          message: 'Сегмент успешно изменен',
        }, { root: true });
      } catch (err) {
        console.error(err);
        context.dispatch('main/alert', {
          color: 'error',
          message: 'Произошла ошибка. Пожалуйста, попробуй еще раз',
        }, { root: true });
      }
    },
  },
};
